var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',{staticClass:"mdc-list pr-4"},[_c('mdc-button',{staticClass:"mdc-list-item w-full",attrs:{"ripple":""},nativeOn:{"click":function($event){return _vm.$router.push('/email')}}},[_c('i',{staticClass:"material-icons mdc-list-item__graphic",attrs:{"aria-hidden":"true"}},[_vm._v("keyboard_return")]),_c('span',{staticClass:"mdc-list-item__text"},[_vm._v("Back to Emails")])]),_c('hr',{staticClass:"mdc-list-divider"}),_c('mdc-button',{staticClass:"mdc-list-item w-full",attrs:{"ripple":""},nativeOn:{"click":function($event){return _vm.$store.commit('contacts/newContact')}}},[_c('i',{staticClass:"material-icons mdc-list-item__graphic",attrs:{"aria-hidden":"true"}},[_vm._v("person_add")]),_c('span',{staticClass:"mdc-list-item__text"},[_vm._v("Create Contact")])]),_c('mdc-button',{staticClass:"mdc-list-item w-full",attrs:{"ripple":""},nativeOn:{"click":function($event){return _vm.$store.commit('contacts/importContacts')}}},[_c('i',{staticClass:"material-icons mdc-list-item__graphic",attrs:{"aria-hidden":"true"}},[_vm._v("playlist_add")]),_c('span',{staticClass:"mdc-list-item__text"},[_vm._v("Import Contacts")])]),_c('hr',{staticClass:"mdc-list-divider"}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.search),expression:"search"}],staticClass:"mdc-list-item w-full bg-gray-lighter",attrs:{"type":"search","placeholder":"Search Contacts","ripple":""},domProps:{"value":(_vm.search)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.search=$event.target.value},_vm.setSearch]}}),_c('mdc-button',{staticClass:"mdc-list-item w-full",class:{
      'mdc-list-item--selected':
        _vm.$store.state.contacts.pagination.view === 'all',
    },attrs:{"ripple":""},nativeOn:{"click":function($event){return _vm.setContactView('all')}}},[_c('i',{staticClass:"material-icons mdc-list-item__graphic",attrs:{"aria-hidden":"true"}},[_vm._v("person")]),_c('span',{staticClass:"mdc-list-item__text"},[_vm._v("Contacts")]),(_vm.$store.state.contacts.pagination.view === 'all')?_c('span',{staticClass:"mdc-list-item__meta",domProps:{"textContent":_vm._s(("(" + (_vm.$store.state.contacts.total) + ")"))}}):_vm._e()]),_c('mdc-button',{staticClass:"mdc-list-item w-full",class:{
      'mdc-list-item--selected':
        _vm.$store.state.contacts.pagination.view === 'disabled',
    },attrs:{"ripple":""},nativeOn:{"click":function($event){return _vm.setContactView('disabled')}}},[_c('i',{staticClass:"material-icons mdc-list-item__graphic",attrs:{"aria-hidden":"true"}},[_vm._v("person_outline")]),_c('span',{staticClass:"mdc-list-item__text"},[_vm._v("Disabled Contacts")]),(_vm.$store.state.contacts.pagination.view === 'disabled')?_c('span',{staticClass:"mdc-list-item__meta",domProps:{"textContent":_vm._s(("(" + (_vm.$store.state.contacts.total) + ")"))}}):_vm._e()]),_c('hr',{staticClass:"mdc-list-divider"}),_c('mdc-button',{staticClass:"mdc-list-item w-full",class:{
      'mdc-list-item--selected':
        _vm.$store.state.contacts.pagination.view === 'groups',
    },attrs:{"disabled":_vm.$store.state.contacts.groups.length === 0,"ripple":""},nativeOn:{"click":function($event){return _vm.setContactView('groups')}}},[_c('i',{staticClass:"material-icons mdc-list-item__graphic",attrs:{"aria-hidden":"true"}},[_vm._v("group")]),_c('span',{staticClass:"mdc-list-item__text"},[_vm._v("Groups")]),_c('span',{staticClass:"mdc-list-item__meta",domProps:{"textContent":_vm._s(("(" + (_vm.$store.state.contacts.groups.length) + ")"))}})]),_c('mdc-button',{staticClass:"mdc-list-item w-full",attrs:{"ripple":""},nativeOn:{"click":function($event){return _vm.$store.commit('contacts/newGroup')}}},[_c('i',{staticClass:"material-icons mdc-list-item__graphic",attrs:{"aria-hidden":"true"}},[_vm._v("group_add")]),_c('span',{staticClass:"mdc-list-item__text"},[_vm._v("Create Group")])]),_vm._l((_vm.$store.state.contacts.groups),function(group){return _c('mdc-button',{key:group.hash,staticClass:"mdc-list-item w-full",class:{
      'mdc-list-item--selected':
        _vm.$store.state.contacts.pagination.view !== 'all' &&
        _vm.$store.state.contacts.pagination.view !== 'groups' &&
        _vm.$store.getters['contacts/selectedGroup'] &&
        _vm.$store.getters['contacts/selectedGroup'].hash === group.hash,
    },attrs:{"ripple":""},nativeOn:{"click":function($event){return _vm.setContactView(group.hash)}}},[_c('i',{staticClass:"material-icons mdc-list-item__graphic",attrs:{"aria-hidden":"true"}},[_vm._v("group")]),_c('span',{staticClass:"mdc-list-item__text",domProps:{"textContent":_vm._s(group.name)}}),_c('span',{staticClass:"mdc-list-item__meta",domProps:{"textContent":_vm._s(("(" + (group.contacts_count) + ")"))}})])})],2)}
var staticRenderFns = []

export { render, staticRenderFns }