<template>
  <nav class="mdc-list pr-4">
    <mdc-button
      class="mdc-list-item w-full"
      ripple
      @click.native="$router.push('/email')"
    >
      <i class="material-icons mdc-list-item__graphic" aria-hidden="true"
        >keyboard_return</i
      >
      <span class="mdc-list-item__text">Back to Emails</span>
    </mdc-button>

    <hr class="mdc-list-divider" />

    <mdc-button
      ripple
      class="mdc-list-item w-full"
      @click.native="$store.commit('contacts/newContact')"
    >
      <i class="material-icons mdc-list-item__graphic" aria-hidden="true"
        >person_add</i
      >
      <span class="mdc-list-item__text">Create Contact</span>
    </mdc-button>
    <mdc-button
      ripple
      class="mdc-list-item w-full"
      @click.native="$store.commit('contacts/importContacts')"
    >
      <i class="material-icons mdc-list-item__graphic" aria-hidden="true"
        >playlist_add</i
      >
      <span class="mdc-list-item__text">Import Contacts</span>
    </mdc-button>

    <hr class="mdc-list-divider" />

    <input
      v-model="search"
      type="search"
      placeholder="Search Contacts"
      ripple
      class="mdc-list-item w-full bg-gray-lighter"
      @input="setSearch"
    />

    <mdc-button
      :class="{
        'mdc-list-item--selected':
          $store.state.contacts.pagination.view === 'all',
      }"
      ripple
      class="mdc-list-item w-full"
      @click.native="setContactView('all')"
    >
      <i class="material-icons mdc-list-item__graphic" aria-hidden="true"
        >person</i
      >
      <span class="mdc-list-item__text">Contacts</span>
      <span
        v-if="$store.state.contacts.pagination.view === 'all'"
        class="mdc-list-item__meta"
        v-text="`(${$store.state.contacts.total})`"
      />
    </mdc-button>

    <mdc-button
      :class="{
        'mdc-list-item--selected':
          $store.state.contacts.pagination.view === 'disabled',
      }"
      ripple
      class="mdc-list-item w-full"
      @click.native="setContactView('disabled')"
    >
      <i class="material-icons mdc-list-item__graphic" aria-hidden="true"
        >person_outline</i
      >
      <span class="mdc-list-item__text">Disabled Contacts</span>
      <span
        v-if="$store.state.contacts.pagination.view === 'disabled'"
        class="mdc-list-item__meta"
        v-text="`(${$store.state.contacts.total})`"
      />
    </mdc-button>

    <hr class="mdc-list-divider" />

    <mdc-button
      :class="{
        'mdc-list-item--selected':
          $store.state.contacts.pagination.view === 'groups',
      }"
      :disabled="$store.state.contacts.groups.length === 0"
      ripple
      class="mdc-list-item w-full"
      @click.native="setContactView('groups')"
    >
      <i class="material-icons mdc-list-item__graphic" aria-hidden="true"
        >group</i
      >
      <span class="mdc-list-item__text">Groups</span>
      <span
        class="mdc-list-item__meta"
        v-text="`(${$store.state.contacts.groups.length})`"
      />
    </mdc-button>

    <mdc-button
      ripple
      class="mdc-list-item w-full"
      @click.native="$store.commit('contacts/newGroup')"
    >
      <i class="material-icons mdc-list-item__graphic" aria-hidden="true"
        >group_add</i
      >
      <span class="mdc-list-item__text">Create Group</span>
    </mdc-button>

    <mdc-button
      v-for="group in $store.state.contacts.groups"
      :key="group.hash"
      :class="{
        'mdc-list-item--selected':
          $store.state.contacts.pagination.view !== 'all' &&
          $store.state.contacts.pagination.view !== 'groups' &&
          $store.getters['contacts/selectedGroup'] &&
          $store.getters['contacts/selectedGroup'].hash === group.hash,
      }"
      ripple
      class="mdc-list-item w-full"
      @click.native="setContactView(group.hash)"
    >
      <i class="material-icons mdc-list-item__graphic" aria-hidden="true"
        >group</i
      >
      <span class="mdc-list-item__text" v-text="group.name" />
      <span class="mdc-list-item__meta" v-text="`(${group.contacts_count})`" />
    </mdc-button>
  </nav>
</template>

<script>
import debounce from 'debounce';
import MdcButton from '@/components/mdc/button.vue';

export default {
  components: {
    MdcButton,
  },

  data: () => ({
    search: '',
  }),

  mounted() {
    this.setSearch();
    this.setSearch = debounce(this.setSearch, 600);
  },

  methods: {
    setContactView(view) {
      this.search = '';
      this.$store.commit('contacts/setView', view);
    },
    setSearch() {
      this.$store.commit('contacts/setSearch', this.search);
    },
  },
};
</script>
